<template>
<!--  -->
    <div class="footer-box">
        <img class="icon" onclick="javascript:document.getElementById('banner').scrollIntoView();" @mouseover="isMove = true" @mouseleave="isMove = false" :src="`https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/footIcon${isMove ? '-active' : ''}.png`" alt="">
        <div v-if="$store.state.isPhone" class="phone-box">
            <div>版权所有©上海紫雄网络科技有限公司</div>
            <a href='https://beian.miit.gov.cn/#/Integrated/index'>沪ICP备20013925号-1</a>
            <a href='http://www.zixiong.vip/pcsite/zixiong.vip.png'>域名证书</a>
        </div>
        <div v-else class="pc-box">
            <div>版权所有©上海紫雄网络科技有限公司</div>
            <div>
                <a href='https://beian.miit.gov.cn/#/Integrated/index'>沪ICP备20013925号-1</a>
                <a href='http://www.zixiong.vip/pcsite/zixiong.vip.png'>域名证书</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            isMove:false
        }
    },
   
}
</script>
<style lang="scss" scoped>
.footer-box{
    background: #222222;
    padding: 2.87rem 0;
    text-align: center;
    position: relative;
    font-family: Arial-BoldMT;
    font-size: 1rem;
    color: #919191;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.33rem;
    .icon{
        position: absolute;
        width: 4.53rem;
        height: auto;
        top: -1.8rem;
        left: calc(50% - 2.27rem);
        z-index: 5;
        cursor: pointer;
    }
    a{
        font-family: Arial-BoldMT;
        font-size: 1rem;
        color: #919191;
        letter-spacing: 0;
        text-align: center;
        line-height: 1.33rem;
    }
    div{
        margin-bottom: 20px;
        a{
            margin: 0 10px;
        }
    }

    .pc-box{
        display:flex;
        justify-content: space-between;
        padding: 0 40px;
        line-height: 61px;
        font-size: 1.2rem;
        margin-bottom: 0;
        a{
         font-size: 1.2rem;
        }
    }
}
</style>
