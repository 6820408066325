import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPhone:false, //是否是手机
  },
  mutations: {
    changePhone(state,status){
      state.isPhone = status
    }
  },
  actions: {
  },
  modules: {
  }
})
