import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/termsOfService',
    name: 'termsOfService',
    component: () => import('../views/termsOfService.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('../views/privacyPolicy.vue')
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: () => import('../views/gdpr.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
