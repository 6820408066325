<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit(
      "changePhone",
      document.documentElement.clientWidth < 576
    );
  },
  mounted() {
    window.onresize = () => {
      this.$store.commit(
        "changePhone",
        document.documentElement.clientWidth < 576
      );
    };
  },
  methods: {},
  watch: {
    "$route.path": function (newVal, oldVal) {
      window.scrollTo(0,0)
    },
  },
};
</script>
<style lang="scss">
// @import "./assets/css/base.css";
html {
  overflow-x: hidden;
  scroll-behavior:auto;
}
body::-webkit-scrollbar {
  display: none;
}
* {
  box-sizing: border-box;
}
@font-face {
  font-family: Arial-BoldMT;
  src: url("https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/arialbd.ttf");
}
@font-face {
  font-family: Arial-ItalicMT;
  src: url("https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/arialbi.ttf");
}
</style>
