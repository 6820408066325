<template>
  <div>
    <Navbar />
    <div :class="!$store.state.isPhone ? 'page' : 'page  active'">
      <b-container class="home" fluid>
        <div
          id="banner"
          :class="!$store.state.isPhone ? 'back' : 'back  active'"
        >
          <block v-if="false">
            <div class="top-box" v-if="$store.state.isPhone">
              <div class="top-title">We Are</div>
              <div class="top-title">JoyHunters!</div>
              <div class="top-desc">
                We are finding, playing, creating games.
              </div>
            </div>
            <div class="btn-box" v-else>
              <div
                class="btn active"
                onclick="javascript:document.getElementById('games-box').scrollIntoView()"
              >
                OUR GAMES
              </div>
              <div
                class="btn"
                onclick="javascript:document.getElementById('contact-us').scrollIntoView()"
              >
                CONTACT US
              </div>
            </div>
          </block>
        </div>
      </b-container>

      <div class="about-us" id="about-us">
        <div class="title">关于我们</div>
        <div class="desc">
          <b-col cols="6" offset="3" class="box" v-if="!$store.state.isPhone">
            <img
              class="left"
              src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/1.png"
              alt=""
            />
            上海紫雄网络科技有限公司是一家布局全球的游戏发行商。我们的团队经验丰富且来自全球各地，我们发现游戏、创造游戏，寻找符合各个不同地区用户口味的游戏品类。我们希望可以通过有趣和独特的游戏使您享受更有趣而充实的的休闲生活
            <img
              class="right"
              src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/2.png"
              alt=""
            />
          </b-col>

          <div v-else>
            <img
              class="left"
              src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/1.png"
              alt=""
            />
            上海紫雄网络科技有限公司是一家布局全球的游戏发行商。我们的团队经验丰富且来自全球各地，我们发现游戏、创造游戏，寻找符合各个不同地区用户口味的游戏品类。我们希望可以通过有趣和独特的游戏使您享受更有趣而充实的的休闲生活
            <img
              class="right"
              src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/2.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="games-box" id="games-box">
        <div class="title">我们的热门游戏</div>
        <!-- <b-row class="img-list" v-if="!$store.state.isPhone">
          <img src="../assets/game-1.jpg" alt="" />
          <img src="../assets/game-1.jpg" alt="" />
          <img src="../assets/game-1.jpg" alt="" />
      </b-row> -->

        <div class="img-list">
          <img
            src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/game-3.jpg?time=1"
            alt=""
          />
          <img
            src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/game-4.jpg?time=2"
            alt=""
          />
          <img
            src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/game-5.jpg?time=3"
            alt=""
          />
        </div>
      </div>
      <div class="contact-us" id="contact-us">
        <div class="title">联系我们</div>
        <div class="desc">
          <img
            src="https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/email.png"
            alt=""
          /><span>admin@zixiong.vip</span>
        </div>
        <div class="desc">
          <img
            src="../assets/address-icon.png"
            alt=""
          /><span>中国（上海）自由贸易试验区祥科路58号1幢215-01</span>
        </div>
        <div class="desc">
        </div>
      </div>
    </div>
    <Footbar />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footbar from "@/components/Footbar.vue";
export default {
  name: "Home",
  components: {
    Navbar,
    Footbar,
  },
  created() {
    // window.scrollTo({top:0})
    document.body.scrollTop = 0;
  },
};
</script>

<style lang='scss' scoped>
.home {
  margin: 0;
  padding: 0;
}
.back {
  position: relative;
  padding: 35% 0 0%;
  height: auto;
  background: url("https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/back.png?time=2")
    no-repeat;
  background-size: cover;
  background-position: center;
  &.active {
    background: url("https://public-store-1301843298.cos.ap-shanghai.myqcloud.com/Joyhunters%E5%AE%98%E7%BD%91/phone_back.png?time=2")
      no-repeat;
    background-size: cover;
    background-position: center;
    height: 24.67rem;
  }
  .top-box {
    position: absolute;
    bottom: 6.6rem;
    width: 100%;
    text-align: center;
    .top-title {
      font-family: Arial-BoldMT;
      font-size: 2.13rem;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      line-height: 2.67rem;
    }
    .top-desc {
      padding-top: 0.47rem;
      font-family: Arial-ItalicMT;
      font-size: 0.87rem;
      color: #ffffff;
      letter-spacing: 1px;
    }
  }
  .btn-box {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    .btn {
      border-radius: 0.27rem;
      font-family: Arial-BoldMT;
      font-size: 1rem;
      color: #ffffff;
      margin: 0 2rem 5%;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      padding: 0.5rem 2rem;
      border: 1px solid #ffffff;
      &.active {
        border: none;
        background-image: linear-gradient(180deg, #00a9eb 0%, #0070d3 100%);
      }
    }
  }
}
.title {
  font-family: Arial-BoldMT;
  font-size: 2.4rem;
  color: #2b2b2b;
  font-weight: 700;
  text-align: center;
}
.about-us {
  padding: 80px 0;
  text-align: center;
  .desc {
    position: relative;
    display: inline-block;
    margin: 2rem auto 0;
    font-family: Arial-BoldMT;
    font-size: 1.13rem;
    color: #919191;
    text-align: center;
    line-height: 40px;
    .box {
      padding: 0 5%;
      position: relative;
    }
    img {
      position: absolute;
      width: 36px;
      height: auto;
      top: 10px;
      &.left {
        left: calc(-40px + 5%);
      }
      &.right {
        right: calc(-40px + 5%);
      }
    }
  }
}

.games-box {
  padding: 5.33rem 0;
  .img-list {
    margin-top: 3.33rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: 17rem;
      height: 17rem;
      border-radius: 1.25rem;
      margin: 1rem 1.33rem;
      display: block;
    }
  }
}

.contact-us {
  background: #fafafa;
  padding: 80px 0 90px;
  text-align: center;
  .desc {
    font-family: Arial-ItalicMT;
    font-size: 18px;
    color: black;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
    // cursor: pointer;
    padding: 40px 10px 0;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
}

.page.active {
  .title {
    font-size: 1.87rem;
    font-family: Arial-BoldMT;
    color: #2b2b2b;
    text-align: center;
  }
  .about-us {
    padding: 3.33rem 0 0;
    .desc {
      width: 80%;
      font-family: ArialMT;
      font-size: 1.07rem;
      color: #919191;
      letter-spacing: 0;
      text-align: center;
      line-height: 2.13rem;
      img {
        width: 1.2rem !important;
      }
    }
  }
  .games-box {
    padding-top: 4.73rem;
    .img-list {
      margin-top: 2.13rem;
      img {
        width: 10rem;
        height: auto;
        margin: 0 1rem 1.33rem;
      }
    }
  }
  .contact-us {
    padding-top: 3.33rem 0 5.67rem;
  }
}
</style>